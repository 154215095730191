.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.arrow {
  display: flex; /* Ensure the arrow spans are in a row */
  margin-left: 15px; /* Space between text and arrow */
  transform: translate(50%, 50%);
  transform: rotate(-90deg);
}

.arrow span {
  display: block;
  width: 8px; /* Adjust width */
  height: 8px; /* Adjust height */
  border-bottom: 2px solid white; /* Arrow line */
  border-right: 2px solid white; /* Arrow line */
  transform: rotate(45deg); /* Rotate to point right */
  margin: -4px; /* Adjust margin to position correctly */
  animation: animate 2s infinite;
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
      opacity: 0;
      transform: rotate(45deg) translate(-6px, -6px); /* Start closer to the left */
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: 0;
      transform: rotate(45deg) translate(6px, 6px); /* Move towards the right */
  }
}